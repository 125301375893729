<template>
    <div>
        <div v-if="false">
            <Breadcrumbs :data="menus"/>
        </div>
        <div>
            <form class="pure-form pure-form-stacked">
                <fieldset>
                    <legend></legend>
                    <div class="pure-g">
                        <div class="pure-u-1-6">
                            <label for="multi-first-name"></label>
                            <input type="date"
                                   id="multi-first-name"
                                   class="pure-u-23-24"
                                   @change="dump()"
                                   style="min-width:180px;"
                                   v-model="dateRange[0]"/>
                        </div>
                        <div class="pure-u-1-6">
                            <label for="multi-last-name"></label>
                            <input type="date"
                                   id="multi-last-name"
                                   style="min-width:180px;"
                                   class="pure-u-23-24"
                                   v-model="dateRange[1]"/>
                        </div>
                        <div class="pure-u-1-4">
                            <label for="multi-state"></label>
                            <select id="multi-state" class="pure-u-23-24" v-model="tableFilter.tz">
                                <option v-for="(val, idx) in options.tzs" :key="idx" :value="tzi(val.offset)">
                                    {{ val.label }}
                                </option>
                            </select>
                        </div>
                        <!--                        <div class="pure-u-1-8">-->
                        <!--                            <label for="shortcode"></label>-->
                        <!--                            <input type="text" id="shortcode" class="pure-u-23-24" v-model="tableFilter.shortcode"-->
                        <!--                                   placeholder="shortcode"/>-->
                        <!--                        </div>-->
                        <!--                        <div class="pure-u-1-8">-->
                        <!--                            <label for="keyword"></label>-->
                        <!--                            <input type="text" id="keyword" class="pure-u-23-24" v-model="tableFilter.keyword"-->
                        <!--                                   placeholder="keyword"/>-->
                        <!--                        </div>-->
                        <div class="pure-u-1-4">
                            <div style="padding: 0.3em;">
                                <button type="submit" class="pure-button pure-button-primary"
                                        style="margin-right: 0.4em;" @click="refreshData();">Query
                                </button>
                                <!--                                <button class="pure-button" style="margin-right: 0.4em;" @click="reset()">Reset</button>-->
                                <button type="submit" class="pure-button" @click="doExport()">Export</button>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
        <div>
            <table class="pure-table pure-table-bordered" style="width: 100%; font-size: 14px;">
                <thead>
                <tr>
                    <th>Date</th>
                    <!--                    <th>ServiceID</th>-->
                    <!--                    <th>Country</th>-->
                    <th>Operator</th>
                    <th>SC</th>
                    <th>KW</th>
                    <th>SubscriptionNo.</th>
                    <th>CancelNo.</th>
                    <th>BillingNo.</th>
<!--                    <th>Est. Revenue $</th>-->
                </tr>
                </thead>
                <tr v-if="loading">
                    <td style="padding: 3em; text-align: center;" colspan="7">
                        <img src="../../assets/image/loading.svg" alt="loading" style="width: 30px;"/>
                    </td>
                </tr>
                <tr v-if="!loading && tableData.length === 0">
                    <td style="padding: 3em; text-align: center;" colspan="7">
                        EMPTY DATA
                    </td>
                </tr>
                <tbody v-if="!loading">
                <tr v-for="(val,idx) in tableData" :key="idx">
                    <td>{{ val.dt.replaceAll("-", "/") }}</td>
                    <!--                    <td>{{ val.code_id }}</td>-->
                    <!--                    <td>{{ val.country }}</td>-->
                    <td>{{ val.operator }}</td>
                    <td>{{ val.shortcode }}</td>
                    <td>{{ val.keyword }}</td>
                    <td>{{ val.count_sub }}</td>
                    <td>{{ val.count_cancel }}</td>
                    <td>{{ val.count_dn }}</td>
                    <!--                    <td>{{ val.es_revenue.toFixed(2) }}</td>-->
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import {BrowserDefaultTimezone, MinimalTimezoneSet, TimezoneNumberOffset} from "@/utils/tz";
import {CpDashSummarySub, CpDashSummarySubExport} from "@/store/api/summary";
import {ToApiDate} from "@/utils/time";
import {APP_SITE} from "@/config/config";

const defaultEnd = new Date();
const defaultStart = new Date();
defaultStart.setTime(defaultStart.getTime() - 3600 * 1000 * 24);
const defaultTz = BrowserDefaultTimezone()

export default {
    name: "SummarySub",
    components: {Breadcrumbs},
    data() {
        return {
            menus: [{label: 'Dash', to: '/cpdash/dash'}, {label: 'SubscriptionSummary', to: ''}],
            options: {tzs: MinimalTimezoneSet},
            tableFilter: {
                dt_from: '',
                dt_to: '',
                tz: defaultTz,
                shortcode: '',
                keyword: '',
                site: APP_SITE,
            },
            loading: true,
            tableData: [],
            dateRange: [ToApiDate(defaultTz, defaultStart), ToApiDate(defaultTz, defaultEnd)],
        }
    },
    mounted() {
        console.log(this.dateRange);
        this.refreshData();
    },
    methods: {
        tzi(val) {
            return TimezoneNumberOffset(val)
        },
        dump() {
            console.log(this.dateRange);
        },
        reset() {
            this.tableFilter.shortcode = '';
            this.tableFilter.keyword = '';
            this.tableFilter.dt_from = ToApiDate(defaultTz, defaultStart);
            this.tableFilter.dt_to = ToApiDate(defaultTz, defaultStart);
            this.refreshData();
        },
        doExport: function () {
            this.loading = true;
            this.exportLink = CpDashSummarySubExport(this.tableFilter).then((resp) => {
                this.loading = false;
                window.location.href = resp.data.data.link;
            }).finally(() => {
                this.loading = false;
            });
        },
        refreshData() {
            this.loading = true;
            this.tableData = [];
            this.tableFilter.dt_from = this.dateRange[0];
            this.tableFilter.dt_to = this.dateRange[1];
            CpDashSummarySub(this.tableFilter).then((resp) => {
                if (!resp.data || !resp.data.data) {
                    this.tableData = [];
                } else {
                    this.tableData = resp.data.data;
                }
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>

<style scoped lang="scss">
.filter {
    margin-top: 1em;
}

.table-main {
    margin: 1em 0;
}

.pure-form input[type=date] {
    padding: 0.34em 0.6em;
}
</style>